import React, { useState, useEffect, useMemo } from 'react';
import DashboardDataContext, {
  DashboardDataContextType
} from 'ecto-common/lib/hooks/DashboardDataContext';
import {
  createEquipmentMap,
  createFlatNodeTree,
  createNodeMap
} from 'ecto-common/lib/utils/locationUtils';
import store, { useCommonDispatch } from 'ecto-common/lib/reducers/storeCommon';
import { Provider } from 'react-redux';

import _ from 'lodash';
import TimeRangeContext from 'ecto-common/lib/Dashboard/context/TimeRangeContext';
import { TimeRangeOptions } from 'ecto-common/lib/types/TimeRangeOptions';
import EventHubService, {
  EVENT_HUB_SERVICE_DEFAULT_TIMEOUT_VALUE,
  EventHubServiceContext
} from 'ecto-common/lib/EventHubConnection/EventHubService';
import { getRoomToken } from '../../components/App/app_util';
import './EctocloudDashboardEnvironment.css';
import ClientNr from '../../components/App/ClientNr';
import { setNodes } from 'ecto-common/lib/actions/getNodes';
import { setEquipmentTypes } from 'ecto-common/lib/actions/getEquipmentTypes';
import { setEnums } from 'ecto-common/lib/actions/getEnums';
import { setSignalTypes } from 'ecto-common/lib/actions/setSignalTypes';
import APIGen, {
  NodeResponseModel,
  SignalTypeResponseModel,
  EquipmentTypeResponseModel,
  GetEnumsAndFixedConfigurationsResponseModel
} from 'ecto-common/lib/API/APIGen';
import { GridType } from 'ecto-common/lib/API/EctotableClientAPIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useQuery } from '@tanstack/react-query';
import localStore from 'store';

const MEDICON_VILLAGE_CONTEXT_SETTINGS: ApiContextSettings = {
  tenantId: 'se-mediconvillage'
};
const MEDICON_VILLAGE_NODE_ID = '126d1676-cc57-44ec-b7eb-1fafa0bc1393';
const RETRY_TIMEOUT = 10000;

const msalConfiguration = {
  acquireTokenSilent: () => Promise.resolve({ accessToken: getRoomToken() }),
  acquireTokenRedirect: () => {}
};

const getResourcesPromise = () => {
  return Promise.all([
    APIGen.Nodes.getNodes.promise(MEDICON_VILLAGE_CONTEXT_SETTINGS, {}, null),
    APIGen.Nodes.getGrids.promise(MEDICON_VILLAGE_CONTEXT_SETTINGS, null),
    APIGen.Enums.getEnumsAndFixedConfigurations.promise(
      MEDICON_VILLAGE_CONTEXT_SETTINGS,
      null
    ),
    APIGen.SignalTypes.getAllSignalTypes.promise(
      MEDICON_VILLAGE_CONTEXT_SETTINGS,
      null
    ),
    APIGen.Equipments.getEquipmentTypes.promise(
      MEDICON_VILLAGE_CONTEXT_SETTINGS,
      null
    )
  ] as const);
};

const TimeRangeValue = {
  timeRangeOption: TimeRangeOptions.DAY,
  referenceDate: null,

  // Each consumer of time range adds or removes them self from the time range user

  addTimeRangeConsumer: (_consumer) => {},
  removeTimeRangeConsumer: (_consumer) => {}
};

const EctotableDashboardEnvironment = ({ children }) => {
  const [dashboardResourcesValue, setDashboardResourcesValue] = useState(null);

  // @ts-ignore-next-line: Need to investigate if mock msalConfiguration is enough
  const connection = useMemo(
    () =>
      new EventHubService(
        MEDICON_VILLAGE_CONTEXT_SETTINGS,
        EVENT_HUB_SERVICE_DEFAULT_TIMEOUT_VALUE,
        [],
        msalConfiguration as unknown as IPublicClientApplication,
        null
      ),
    []
  );

  const dispatch = useCommonDispatch();

  useEffect(() => {
    if (connection) {
      connection.connect();

      return () => {
        connection.disconnect();
      };
    }
  }, [connection]);

  const loadResourcesQuery = useQuery({
    queryKey: ['ectotable-dashboard-env', MEDICON_VILLAGE_CONTEXT_SETTINGS],
    queryFn: getResourcesPromise,
    refetchOnWindowFocus: false,
    retryDelay() {
      return RETRY_TIMEOUT;
    }
  });

  useEffect(() => {
    const cacheKey = 'ectotable-dashboard-env-';

    let resourceData = loadResourcesQuery.data;
    if (resourceData == null) {
      let storedData = localStore.get(cacheKey);
      if (storedData != null) {
        resourceData = JSON.parse(storedData) as [
          NodeResponseModel[],
          string[],
          GetEnumsAndFixedConfigurationsResponseModel,
          SignalTypeResponseModel[],
          EquipmentTypeResponseModel[]
        ];
      }
    }

    if (loadResourcesQuery.data) {
      const [nodeList, gridTypes, enums, signalTypes, equipmentTypes] =
        loadResourcesQuery.data;
      const gridTree = createFlatNodeTree(gridTypes as GridType[], nodeList);
      const nodeTree = gridTree.nodeTree;
      const nodeMap = createNodeMap(nodeTree);
      const equipmentMap = createEquipmentMap(nodeTree);
      const signalTypesMap = _.keyBy(signalTypes, 'id');
      const signalTypesNameMap = _.keyBy(signalTypes, 'name');
      const signalUnitTypesMap = _.keyBy(enums.units, 'id');
      const _equipmentTypes = equipmentTypes.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const equipmentTypesMap = _.keyBy(equipmentTypes, 'equipmentTypeId');

      // @ts-ignore-next-line: Set nodes type signature is dynamic unfortunately, need to be fixed
      dispatch(setNodes([nodeList, gridTypes]));
      dispatch(setEnums(enums));
      dispatch(setSignalTypes(signalTypes));
      dispatch(setEquipmentTypes(equipmentTypes));
      setDashboardResourcesValue({
        nodeMap,
        gridTypes,
        isAdmin: false,
        equipmentMap,
        signalTypesMap,
        signalTypesNameMap,
        signalUnitTypesMap,
        equipmentTypes: _equipmentTypes,
        signalProviderTypes: enums.signalProviderTypes,
        equipmentTypesMap,
        isLoadingResources: false
      });
      localStore.set(cacheKey, JSON.stringify(resourceData));
    } else if (loadResourcesQuery.error) {
      setDashboardResourcesValue({
        hasError: true,
        isLoadingResources: false
      });
    }
  }, [loadResourcesQuery.data, loadResourcesQuery.error]);

  useEffect(() => {
    setDataContextValue((oldValue) => ({
      ...oldValue,
      hasError: false,
      isLoadingResources: loadResourcesQuery.isLoading
    }));
  }, [loadResourcesQuery.isLoading]);

  const [dataContextValue, setDataContextValue] =
    useState<DashboardDataContextType>({
      nodeId: MEDICON_VILLAGE_NODE_ID,
      isLoadingResources: true,
      cacheContext: new Map(),
      equipmentTypesMap: {},
      signalTypesMap: {},
      signalTypesNameMap: {},
      signalUnitTypesMap: {},
      signalProviderTypes: [],
      gridTypes: [],
      isAdmin: false,
      equipmentTypes: [],
      nodeMap: {},
      equipmentMap: {},
      setNode: null,
      hasError: false
    });

  useEffect(() => {
    setDataContextValue((oldValue) => ({
      ...oldValue,
      ...dashboardResourcesValue
    }));
  }, [dashboardResourcesValue]);

  return (
    <Provider store={store}>
      <EventHubServiceContext.Provider value={connection}>
        <TimeRangeContext.Provider value={TimeRangeValue}>
          <DashboardDataContext.Provider value={dataContextValue}>
            <div className="EctocloudEnvironment__container">
              {children}
              <ClientNr />
            </div>
          </DashboardDataContext.Provider>
        </TimeRangeContext.Provider>
      </EventHubServiceContext.Provider>
    </Provider>
  );
};

const EctotableDashboardEnvironmentMemo = React.memo(
  EctotableDashboardEnvironment
);

const EctotableDashboardEnvironmentContainer = ({ children }) => {
  return (
    <Provider store={store}>
      <EctotableDashboardEnvironmentMemo>
        {children}
      </EctotableDashboardEnvironmentMemo>
    </Provider>
  );
};

export default React.memo(EctotableDashboardEnvironmentContainer);
