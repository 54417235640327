import React, { Component, useMemo } from 'react';
import createEctotableAPIFetch from '../../utils/createEctotableAPIFetch';
import { createExternalAPIFetch } from 'ecto-common/lib/utils/createAPIFetch';
import {
  setAPIFetch,
  setExternalAPIFetch
} from 'ecto-common/lib/utils/APIFetchInstance';
import {
  ROLES,
  getRoleRoomCompanyAndToken,
  setRoomToken,
  MultiViewTypes
} from './app_util';
import styles from './MultiApp.module.css';
import App from './App';
import queryString from 'query-string';
import roomConfig from '../../config/roomConfig.json';
import ectogridLogo from '../../assets/logo.svg';
import eonLogo from '../../assets/tagline/eon.svg';
import classNames from 'classnames';
import EctotableDashboardEnvironment from 'js/views/DashboardView/EctotableDashboardEnvironment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useResizeDetector } from 'react-resize-detector';

declare global {
  interface Window {
    getDPI: () => number;
    playVideo: () => void;
    pauseVideo: () => void;
  }
}

const AppArea = ({ gridArea, localStoragePrefix, room, role }) => {
  const { ref, width, height } = useResizeDetector();

  const size = useMemo(() => {
    return { width, height };
  }, [width, height]);

  return (
    <div style={{ gridArea }} ref={ref}>
      <App
        isMultiScreen
        room={room}
        size={size}
        role={role}
        localStoragePrefix={localStoragePrefix}
      />
    </div>
  );
};

type EctocloudWrapperProps = {
  children: React.ReactElement;
  roomToken?: string;
};

const queryClient = new QueryClient();

const EctocloudWrapper = ({
  children,
  roomToken = null
}: EctocloudWrapperProps): JSX.Element => {
  if (roomToken != null) {
    return (
      <QueryClientProvider client={queryClient}>
        <EctotableDashboardEnvironment>
          {children}
        </EctotableDashboardEnvironment>
      </QueryClientProvider>
    );
  }

  return children;
};

class MultiApp extends Component {
  constructor(props) {
    super(props);

    // Gets the stored or dynamic role and room to show. No other evaluation is
    // done after this.
    const { room, roomToken } = getRoleRoomCompanyAndToken();

    if (roomToken != null) {
      setAPIFetch(createEctotableAPIFetch(roomToken, room));
    }

    setExternalAPIFetch(createExternalAPIFetch());

    window.getDPI = () => {
      const dpiEl = document.getElementById('dpi');

      if (dpiEl) return dpiEl.offsetHeight;
      return 96; // Desktop
    };

    const params = queryString.parse(location.search);

    if (params.roomToken != null) {
      setRoomToken(params.roomToken);
      window.history.replaceState(null, null, window.location.pathname);
    }
  }

  render() {
    // Gets the stored or dynamic role and room to show. No other evaluation is
    // done after this.
    const { room, roomToken } = getRoleRoomCompanyAndToken('');
    const config = roomConfig[room];
    const multiScreenType = config?.multiScreen;

    if (multiScreenType != null && room != null) {
      if (multiScreenType === MultiViewTypes.MULTI_1_1) {
        return (
          <EctocloudWrapper roomToken={roomToken}>
            <div className={classNames(styles.container_1_1)}>
              <AppArea
                gridArea={'client1'}
                localStoragePrefix="client_multi"
                role={ROLES.combo}
                room={room}
              />
              <AppArea
                key={multiScreenType}
                gridArea={'table'}
                localStoragePrefix="table"
                role={ROLES.table}
                room={room}
              />
            </div>
          </EctocloudWrapper>
        );
      } else {
        let containerClassname = '';
        switch (multiScreenType) {
          case MultiViewTypes.MULTI_2_1_1:
            containerClassname = styles.container_2_1_1;
            break;
          case MultiViewTypes.MULTI_3_1:
            containerClassname = styles.container_3_1;
            break;
          case MultiViewTypes.MULTI_3V_1:
            containerClassname = styles.container_3_1_v;
            break;
          default:
            break;
        }

        return (
          <EctocloudWrapper roomToken={roomToken}>
            <div className={classNames(containerClassname)}>
              <AppArea
                gridArea={'client1'}
                localStoragePrefix="client1"
                role={ROLES.production}
                room={room}
              />
              <AppArea
                gridArea={'client2'}
                localStoragePrefix="client2"
                role={ROLES.consumption}
                room={room}
              />
              <AppArea
                gridArea={'client3'}
                localStoragePrefix="client3"
                role={ROLES.ectocloud}
                room={room}
              />
              <AppArea
                key={multiScreenType}
                gridArea={'table'}
                localStoragePrefix="table"
                role={ROLES.table}
                room={room}
              />
              <div className={styles.logos}>
                <img src={eonLogo} />
                <div className={styles.spacer} />
                <img src={ectogridLogo} />
              </div>
            </div>
          </EctocloudWrapper>
        );
      }
    }

    const { ref, width, height } = useResizeDetector();

    const size = useMemo(() => {
      return { width, height };
    }, [width, height]);

    return (
      <EctocloudWrapper roomToken={roomToken}>
        <div className={classNames(styles.singleApp)} ref={ref}>
          <App size={size} room={room} isMultiScreen={false} />
        </div>
      </EctocloudWrapper>
    );
  }
}

export default MultiApp;
